import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    Layout,
    useTranslate,
} from '@solublestudio/gatsby-theme-soluble-source';
import { HeroSection } from '@solublestudio/idc-design-system';

export default function NotFoundPage() {
    const _ = useTranslate();

    const data = useStaticQuery(graphql`
        {
            logo: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "404-bg" }
            ) {
                childImageSharp {
                    fixed(
                        width: 1200
                        height: 600
                        traceSVG: { color: "#252AD4", background: "#EDECE6" }
                    ) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <Layout
            customSeo={{
                title: _['404.title'].text,
                description: _['404.subtitle'].text,
                noindex: true,
            }}
            navbarFixed={true}
            navbarTransparent={true}>
            <HeroSection
                order={1}
                title={`${_['404.title'].text}<br />${_['404.subtitle'].text}`}
                picture={{ file: data.logo }}
            />
        </Layout>
    );
}
